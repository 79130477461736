import { Text } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: VFC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I’ve tested my API credentials.">
      <Text>
        Inside your Enterprise Okta Admin Panel, click on the "Provisioning"
        tab. Then, click on "Configure API Integration".
      </Text>

      <Img
        className="my-8"
        height={1023}
        src="/images/7ea9e40f-1ba5-400c-bdb0-781982510a66.png"
        width={1596}
      />

      <Text>Click "Enable API Integration".</Text>

      <Img
        className="my-8"
        height={1023}
        src="/images/8133ef41-973a-46c4-abad-5bc66d269e1e.png"
        width={1596}
      />

      <Text>Copy and paste the endpoint into the SCIM 2.0 Base Url field.</Text>

      <CopyInput
        id="okta-endpoint-copy-input"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>
        Copy and paste the Bearer Token into the OAuth Bearer Token field.
      </Text>

      <CopyInput
        id="okta-bearer-token-copy-input"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Text>Click "Test API Credentials" and then "Save".</Text>
    </StepContent>
  );
};

export const configureOktaApiIntegration: StepData = {
  title: 'Configure Okta API Integration',
  component: Component,
};
