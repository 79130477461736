import classNames from 'classnames';
import React from 'react';
import { Check } from 'react-feather';

export const CheckItem = () => (
  <div
    className={classNames(
      'w-6 h-6 rounded-full absolute -top-3 -right-2 flex items-center justify-center transition-opacity bg-primary',
    )}
  >
    <Check className="stroke-white" size={16} strokeWidth={3} />
  </div>
);
