import { useFeatureToggle } from '@flopflip/react-broadcast';
import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from '../../../pages/not_found';
import { Expired } from '../error-pages';
import { IntentRouter } from '../intent-router';
import { PortalSessionProvider } from '../portal-session-provider';
import { SetupLinkHandler } from '../setup-link-handler';

interface NewAdminPortalWrapperProps {}
const isServer = typeof window === 'undefined';

export const NewAdminPortalWrapper: FC<
  Readonly<NewAdminPortalWrapperProps>
> = ({ children }) => {
  // Temporary hotfix to avoid running Cypress against this new code path. DRGN-76 will migrate Cypress
  // to stop running against Development
  const isLocalDevelopment = false;

  const shouldUseNewAdminPortal =
    useFeatureToggle('adminPortalRevamp') && isLocalDevelopment;

  if (shouldUseNewAdminPortal) {
    return (
      <div suppressHydrationWarning>
        {isServer ? null : (
          <Router>
            <Routes>
              <Route element={<SetupLinkHandler />} path="/s/:setupToken" />
              <Route element={<Expired />} path="/expired" />
              <Route element={<NotFound />} path="/not-found" />
              <Route
                element={
                  <PortalSessionProvider>
                    <IntentRouter />
                  </PortalSessionProvider>
                }
                path="*"
              />
            </Routes>
          </Router>
        )}
      </div>
    );
  }
  return <>{children}</>;
};
