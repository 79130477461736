import React, { FC } from 'react';
import { NextStep } from '../../components/next-step';
import { useStepsStore } from './steps-store-provider';

interface StepContentProps {
  confirmLabel: string;
  confirmButtonText?: string;
  isConfirmButtonEnabled?: boolean;
  onStepChange?: () => Promise<void>;
}

export const StepContent: FC<Readonly<StepContentProps>> = ({
  children,
  confirmLabel,
  confirmButtonText,
  isConfirmButtonEnabled = true,
  onStepChange,
}) => {
  const { handleNextStep } = useStepsStore();

  const handleNextChange = async () => {
    await onStepChange?.();
    await handleNextStep();
  };

  return (
    <div>
      {children}

      <NextStep
        buttonText={confirmButtonText}
        disabled={!isConfirmButtonEnabled}
        label={confirmLabel}
        onClick={handleNextChange}
      />
    </div>
  );
};
