import React, { VFC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { DsyncStoreProvider } from '../dsync-store-provider';
import { DirectorySyncProviderSelectionPage } from '../pages/directory-sync-provider-selection-page';
import { DsyncSteps } from '../steps';
import { DsyncRedirector } from './dsync-redirector';

interface DsyncRouterProps {}
export const DsyncRouter: VFC<Readonly<DsyncRouterProps>> = () => (
  <DsyncStoreProvider>
    <Topnav />

    <Routes>
      <Route element={<DsyncRedirector />} path="/">
        <Route element={<DirectorySyncProviderSelectionPage />} path="/new" />
        <Route element={<div>Configured Directory</div>} path="/:directoryId" />
        <Route element={<DsyncSteps />} path="/configure/*" />
      </Route>
    </Routes>
  </DsyncStoreProvider>
);
