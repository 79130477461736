import { Button, Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Card } from '../card';

interface NextStepProps {
  buttonText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
}

export const NextStep: FC<Readonly<NextStepProps>> = ({
  buttonText = 'Continue to Next Step',
  disabled,
  isLoading,
  label,
  onClick: handleClick,
}) => (
  <Card>
    <div className="flex items-center justify-between">
      <Text as="p">{label}</Text>

      <Button disabled={disabled} isLoading={isLoading} onClick={handleClick}>
        {buttonText}
      </Button>
    </div>
  </Card>
);
