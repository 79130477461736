export const extractCurrentStepFromPathname = (
  urlBase: string,
  pathname: string,
): number => {
  const pathnameMatch = pathname.match(
    new RegExp(`${urlBase}\/(?<stepNumber>\\d+)`),
  );

  if (!pathnameMatch?.groups?.stepNumber) {
    throw new Error('Invalid pathname for extractCurrentStepFromPathname');
  }

  return Number(pathnameMatch.groups.stepNumber);
};
