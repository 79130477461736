import { Text } from '@workos-inc/component-library';
import React, { ReactElement } from 'react';
import { Page } from '../layouts/page';

const NotFound = (): ReactElement => (
  <Page>
    <div className="mt-28 flex flex-col items-center">
      <Text as="h1" className="mb-4" size="xxlarge" weight="medium">
        You don't have a Portal session.
      </Text>

      <Text>
        Please try again with a valid portal session to configure Single
        Sign-On.
      </Text>
    </div>
  </Page>
);

export default NotFound;
