import { useFeatureToggle } from '@flopflip/react-broadcast';
import { Button, Text } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { ArrowLeft } from 'react-feather';
import { ExternalLink } from '../link';
import { usePortalSession } from '../portal-session-provider';

interface TopnavProps {}

export const Topnav: VFC<Readonly<TopnavProps>> = () => {
  const hidePoweredByWorkos = useFeatureToggle('hidePoweredByWorkos');

  const { appName, logoUrl, returnUrl, pageTitle } = usePortalSession();

  const handleExitClick = (): void => {
    window.location.href = returnUrl;
  };

  return (
    <header className="sticky top-0 z-10 h-16 border-b border-gray-lightmode-200 bg-white px-8 leading-[4rem]">
      <div className="mx-auto flex h-full max-w-7xl items-center justify-between">
        <Button
          appearance="minimal"
          data-testid="topnav-back-button"
          iconLeft={<ArrowLeft size={16} />}
          onClick={handleExitClick}
        >
          Back to {appName}
        </Button>

        {pageTitle && (
          <Text
            as="h1"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            data-testid="topnav-title"
          >
            {pageTitle}
          </Text>
        )}

        {logoUrl ? (
          <div className="flex w-[125px] items-center">
            <img data-testid="logo" src={logoUrl} />
          </div>
        ) : (
          !hidePoweredByWorkos && (
            <ExternalLink
              newTab
              className="text-gray-lightmode-400"
              href="https://www.workos.com?utm_source=adminportal_nav&utm_medium=setup&utm_campaign=adminportal"
            >
              <Text inheritColor as="p">
                Powered by WorkOS
              </Text>
            </ExternalLink>
          )
        )}
      </div>
    </header>
  );
};
