import { StepData } from '../interfaces/step-data';

export const generateStepData =
  (ConfirmationStep: React.ElementType) =>
  (stepsData: StepData[]): StepData[] => {
    const stepsWithConfirmationData = stepsData.map((stepData) => ({
      ...stepData,
      confirmationStep: false,
    }));

    return [
      ...stepsWithConfirmationData,
      {
        title: 'Confirmation',
        component: ConfirmationStep,
        confirmationStep: true,
      },
    ];
  };
