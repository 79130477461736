import { Dialog } from '@workos-inc/component-library';
import { useRouter } from 'next/router';
import React, { FC, useContext, useState } from 'react';
import { SupportedConnectionType } from '../../../interfaces/supported-connection-type';
import { EventName, SsoProviderLabels } from '../../../utils/constants';
import { graphql } from '../../../utils/graphql';
import { logError } from '../../../utils/logger';
import { ProviderSelection } from '../../provider-selection';
import { StoreContext } from '../../store-provider';
import { stepsConfig } from '../steps/steps-config';

export interface SwitchProviderModalProps {
  open: boolean;
  setOpen(open: boolean): void;
  trackEvent: (
    event_name: string,
    attributes?: { [key: string]: unknown },
  ) => void;
}

export const SwitchProviderModal: FC<Readonly<SwitchProviderModalProps>> = ({
  open,
  setOpen,
  trackEvent,
}) => {
  const router = useRouter();
  const {
    connection: [connection, setConnection],
    domain: [domain, setDomain],
    organization: [organization],
    ssoProvider: [provider, setProvider],
  } = useContext(StoreContext);
  const [switchingProvider, setSwitchingProvider] = useState(false);
  const [selection, setSelection] = useState<
    SupportedConnectionType | undefined
  >(provider);

  const resetModal = () => {
    if (provider !== selection) {
      setSelection(provider);
    }
  };

  const handleSwitchConnection = async (): Promise<void> => {
    if (connection && selection && organization) {
      setSwitchingProvider(true);
      const connectionDomain = domain;

      let response;

      try {
        const { id } = connection;

        await graphql().DeleteConnection({ id });

        response = await graphql().CreateConnection({
          name: organization.name,
          type: selection,
          domains: connectionDomain ? [connectionDomain] : [],
        });
      } catch (e) {
        logError(e);
      }

      const data = response?.data?.portal_createConnection;

      if (data) {
        trackEvent(EventName.IdentityProviderSwitched, {
          from_connection_type: provider,
          to_connection_type: selection,
        });

        setDomain(connectionDomain);
        setProvider(selection);
        setConnection(data);
        setOpen(false);
        setSwitchingProvider(false);
        void router.replace('/sso/1');
      }
    } else {
      location.reload();
    }
  };

  const submitDisabled = provider === selection || !connection;

  return (
    <Dialog
      acceptText="Switch Identity Provider"
      className="max-w-2xl"
      description="This action will reset any information associated with your connection."
      isAcceptDisabled={submitDisabled || switchingProvider}
      onAccept={handleSwitchConnection}
      onOpenChange={(isModalOpen) => {
        resetModal();
        setOpen(isModalOpen);
      }}
      open={open}
      title="Are you sure you want to change your Identity Provider?"
    >
      <ProviderSelection
        onSelection={(selection) => setSelection(selection)}
        providerLabels={SsoProviderLabels}
        providers={Object.keys(stepsConfig) as SupportedConnectionType[]}
        selection={selection}
      />
    </Dialog>
  );
};
