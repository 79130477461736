import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { OverflowPage } from '../../../layouts/page';
import { Article } from '../../components/typography';
import { AnyObject } from '../../hooks/use-record-state';
import { StepDataWithPath } from './interfaces/step-data';
import { Sidebar } from './sidebar';
import { StepTitle } from './step-title';
import { StepsStoreProvider } from './steps-store-provider';

export interface StepNavigationProps {
  onClickSwitchProvider: () => void;
  onUpdateWithChanges: (formData: AnyObject) => Promise<void>;
  provider: DirectoryType | ConnectionType;
  stepsData: StepDataWithPath[];
  stepsUrlBase: string;
}

export const StepNavigation = ({
  children,
  onClickSwitchProvider: handleClickSwitchProvider,
  onUpdateWithChanges: handleOnUpdateWithChanges,
  provider,
  stepsData,
  stepsUrlBase,
}: PropsWithChildren<Readonly<StepNavigationProps>>) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (frameRef?.current) {
      frameRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [pathname]);

  return (
    <StepsStoreProvider
      onUpdateWithChanges={handleOnUpdateWithChanges}
      stepsData={stepsData}
      stepsUrlBase={stepsUrlBase}
    >
      <OverflowPage pageRef={frameRef}>
        <section className="grid grid-cols-[300px_680px] gap-20">
          <Sidebar
            onClickSwitchProvider={handleClickSwitchProvider}
            provider={provider}
            stepsData={stepsData}
          />

          <Article>
            <StepTitle stepsData={stepsData} />

            {children}
          </Article>
        </section>
      </OverflowPage>
    </StepsStoreProvider>
  );
};
