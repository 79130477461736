import is from '@sindresorhus/is';
import { hasProperty } from '@workos-inc/standard/object';
import axios, { AxiosResponse } from 'axios';
import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import { ADMIN_PORTAL_API_URL_COOKIE } from './get-cookies';

export const Intent = t.keyof({ dsync: null, sso: null });

export type Intent = t.TypeOf<typeof Intent>;

const INTENT_COOKIE = '_admin_portal_intent';

export const getIntentFromCookie = (): Intent => {
  const rawCookieValue = Cookies.get(INTENT_COOKIE);

  const cookieValue = Intent.decode(rawCookieValue);

  if (E.isLeft(cookieValue)) {
    throw new Error('Invalid intent cookie');
  }

  return cookieValue.right;
};

export const getIntentFromTokenOrCookie = (
  token: string | undefined,
): Intent | undefined => {
  if (token) {
    return getTokenIntent(token);
  }

  try {
    return getIntentFromCookie();
  } catch {
    return;
  }
};

export const validateToken = async (token: string): Promise<AxiosResponse> => {
  isTokenExpired(token);

  const decoded = jwt.decode(token);

  const isValidJWTToken = decoded && typeof decoded === 'object';
  if (!isValidJWTToken) {
    throw new Error('Invalid token');
  }
  const customBrandedApiUrl =
    isValidJWTToken &&
    hasProperty('api_url')(decoded) &&
    typeof decoded.api_url === 'string'
      ? decoded.api_url
      : undefined;

  if (customBrandedApiUrl) {
    Cookies.set(ADMIN_PORTAL_API_URL_COOKIE, customBrandedApiUrl);
  }

  const apiUrl = customBrandedApiUrl || process.env.NEXT_PUBLIC_API_URL;

  try {
    const response = await axios.post(
      `${apiUrl}/portal/init_session`,
      {
        token,
      },
      { withCredentials: true },
    );

    const { csrf } = response.data;
    const intent = getTokenIntent(token);

    Cookies.set('_csrf', csrf);
    Cookies.set(INTENT_COOKIE, intent);

    return response;
  } catch (error) {
    if (is.error(error)) {
      throw error;
    }

    throw new Error(String(error));
  }
};

const getTokenIntent = (token: string) => {
  const decoded = jwt.decode(token);

  if (typeof decoded === 'object' && decoded?.intent) {
    return decoded.intent;
  }
};

const isTokenExpired = (token: string): boolean => {
  const decoded = jwt.decode(token);

  if (
    typeof decoded === 'object' &&
    decoded !== null &&
    typeof decoded.exp !== 'undefined' &&
    Date.now() >= decoded.exp * 1000
  ) {
    throw new Error('Token expired');
  }

  return false;
};
