import { Button, Pill, Text } from '@workos-inc/component-library';
import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { DirectoryProviderLabels } from '../../../../utils/constants';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelection } from '../../../components/provider-selection';
import { Title } from '../../../components/typography';
import { PageTitle } from '../../../components/with-page-title/with-page-title';
import { useEnabledDirectories } from '../hooks/use-enabled-directories';
import { supportedDirectoryTypes } from '../interfaces/supported-directory-type';

// @TODO: https://linear.app/workos/issue/DRGN-123/wire-page-with-directory-sync-creation-logic-domain-creation
export const DirectorySyncProviderSelectionPage = () => {
  const { setupLink, appName } = usePortalSession();
  // setIsCreatingDirectory is not set to please the linter until
  // until we start using it in DRGN-123
  const [isCreatingDirectory] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<string>();
  const enabledDirectories = useEnabledDirectories(supportedDirectoryTypes);

  return (
    <PageTitle title="Configure Directory Sync">
      <div className="mx-auto flex max-w-7xl flex-col items-center py-8">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ delay: 0.1 }}
        >
          <Title>Directory Sync Configuration</Title>
        </motion.div>
        <motion.div
          animate={{ opacity: 1 }}
          className="mt-1 mb-4 flex items-center gap-1"
          initial={{ opacity: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Text as="p" size="medium">
            Select the Directory Provider you want to use with
          </Text>
          <Pill className="max-w-none" size="small">
            {appName}
          </Pill>
        </motion.div>
        <ProviderSelection
          aria-label="Select Directory Sync Provider"
          onValueChange={(directoryType) => {
            setSelectedProvider(directoryType);
          }}
          providerLabels={DirectoryProviderLabels}
          providers={enabledDirectories}
        />

        <motion.div
          animate={{ opacity: 1, scale: 1 }}
          className="mt-6"
          initial={{ opacity: 0, scale: 0.5 }}
          transition={{ delay: 0.3 }}
        >
          <Button
            className="px-6"
            disabled={!selectedProvider || isCreatingDirectory}
            // onClick={handleSubmitProviderSelection}
            size="large"
          >
            Get Started →
          </Button>
        </motion.div>

        {setupLink ? (
          <Text className="mt-4 text-center">
            Setup Link access available for{' '}
            {formatDistance(new Date(setupLink.expiresAt), Date.now())} or until
            configured.
          </Text>
        ) : null}
      </div>
    </PageTitle>
  );
};
