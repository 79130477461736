import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AnyObject,
  RecordStateDispatcher,
  useRecordState,
} from '../../hooks/use-record-state';
import { StepDataWithPath } from './interfaces/step-data';
import { extractCurrentStepFromPathname } from './utils';

export type StepsFormData<T extends AnyObject> = T;

type StepsStoreContext<T extends AnyObject> = {
  currentStepNumber: number;
  formData: StepsFormData<T>;
  setFormData: RecordStateDispatcher<StepsFormData<T>>;
  handleNextStep: () => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepsContext = createContext<StepsStoreContext<any> | null>(null);

export const useStepsStore = <T extends AnyObject>() => {
  const context = useContext<StepsStoreContext<T> | null>(StepsContext);

  if (!context) {
    throw new Error('useStepsStore can only be used within StepsContext');
  }

  return context;
};

export interface StepsStoreProviderProps<T extends AnyObject> {
  onUpdateWithChanges: (formData: StepsFormData<T>) => Promise<void>;
  stepsData: StepDataWithPath[];
  stepsUrlBase: string;
}

export const StepsStoreProvider = <T extends AnyObject>({
  children,
  onUpdateWithChanges,
  stepsData,
  stepsUrlBase,
}: PropsWithChildren<StepsStoreProviderProps<T>>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentStepNumber = useMemo(
    () => extractCurrentStepFromPathname(stepsUrlBase, pathname),
    [pathname, stepsUrlBase],
  );

  const [formData, setFormData] = useRecordState<StepsFormData<T>>({});

  const handleNextStep = useCallback(async () => {
    const nextStepNumber = currentStepNumber + 1;
    const nextStep = stepsData[nextStepNumber - 1];
    const nextStepPath = `${stepsUrlBase}/${nextStepNumber}`;

    if (nextStep?.confirmationStep && Object.keys(formData).length > 0) {
      await onUpdateWithChanges(formData);
    }

    navigate(nextStepPath);
  }, [
    currentStepNumber,
    formData,
    navigate,
    onUpdateWithChanges,
    stepsData,
    stepsUrlBase,
  ]);

  const context = useMemo<StepsStoreContext<T>>(
    () => ({
      currentStepNumber,
      handleNextStep,
      formData,
      setFormData,
    }),
    [currentStepNumber, formData, setFormData, handleNextStep],
  );

  return (
    <StepsContext.Provider value={context}>{children}</StepsContext.Provider>
  );
};
