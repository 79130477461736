import { unreachable } from '@workos-inc/standard';
import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import {
  DirectoryFragment,
  DirectoryType,
} from '../../../../graphql/generated';
import { useDsyncStore } from '../dsync-store-provider';
import {
  SupportedDirectoryType,
  supportedDirectoryTypes,
} from '../interfaces/supported-directory-type';
import { OktaSteps } from '../providers/okta';

interface DsyncStepsProps {}

export const DsyncSteps: FC<Readonly<DsyncStepsProps>> = () => {
  const { directory } = useDsyncStore();

  const directoryType = getSupportedDirectoryType(directory);

  if (!directoryType) {
    // TODO: Update this to a new Unsupported Provider page in DRGN-111
    return <Navigate to="/not-found" />;
  }

  switch (directoryType) {
    case DirectoryType.AzureScimv2_0:
      return <div>Azure SCIM</div>;
    case DirectoryType.BambooHr:
      return <div>BambooHR</div>;
    case DirectoryType.BreatheHr:
      return <div>BreatheHR</div>;
    case DirectoryType.CyberArkScimV2_0:
      return <div>CyberArk SCIM</div>;
    case DirectoryType.FourthHr:
      return <div>FourthHR</div>;
    case DirectoryType.GoogleWorkspace:
      return <div>Google Workspace</div>;
    case DirectoryType.Hibob:
      return <div>Hibob</div>;
    case DirectoryType.JumpCloudScimV2_0:
      return <div>JumpCloud SCIM</div>;
    case DirectoryType.OktaScimv2_0:
      return <OktaSteps />;
    case DirectoryType.OneLoginScimV2_0:
      return <div>OneLogin SCIM</div>;
    case DirectoryType.PeopleHr:
      return <div>People HR</div>;
    case DirectoryType.PingFederateScimV2_0:
      return <div>PingFederate SCIM</div>;
    case DirectoryType.Rippling:
      return <div>Rippling</div>;
    case DirectoryType.Workday:
      return <div>Workday</div>;
    case DirectoryType.GenericScimv1_1:
      return <div>Generic SCIM v1.1</div>;
    case DirectoryType.GenericScimv2_0:
      return <div>Generic SCIM v2.0</div>;
    default:
      return unreachable(directoryType);
  }
};

const getSupportedDirectoryType = (
  directory: DirectoryFragment,
): SupportedDirectoryType | undefined => {
  if (
    (supportedDirectoryTypes as readonly DirectoryType[]).includes(
      directory.type,
    )
  ) {
    return directory.type as SupportedDirectoryType;
  }
};
