import React, { VFC } from 'react';
import { Title } from '../../components/typography';
import { StepDataWithPath } from './interfaces/step-data';
import { useStepsStore } from './steps-store-provider';

interface StepTitleProps {
  stepsData: StepDataWithPath[];
}

export const StepTitle: VFC<Readonly<StepTitleProps>> = ({ stepsData }) => {
  const { currentStepNumber } = useStepsStore();

  const currentStepData = stepsData[currentStepNumber - 1];

  if (!currentStepData || currentStepData?.confirmationStep) {
    return null;
  }

  return (
    <Title className="mb-4">
      Step {currentStepNumber}: {currentStepData.title}
    </Title>
  );
};
