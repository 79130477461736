import React, { useReducer } from 'react';

export type AnyObject = Record<string, unknown>;

export type StateAction<State extends AnyObject> =
  | Partial<State>
  | ((state: State) => Partial<State>);

export type RecordStateDispatcher<T extends AnyObject> = React.Dispatch<
  StateAction<T>
>;

export const useRecordState = <T extends AnyObject>(
  initialState?: T | AnyObject,
) =>
  useReducer((state: T, action: StateAction<T>) => {
    const newState = typeof action === 'function' ? action(state) : action;
    return { ...state, ...newState };
  }, (initialState ?? {}) as T);
