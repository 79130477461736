import React, { FC, ReactElement } from 'react';
import { AnyObject } from '../../../hooks/use-record-state';
import {
  StepData,
  StepDataWithPath,
  StepNavigation,
  StepNavigationProps,
} from '../../../shared/step-navigation';
import { StepsFormData } from '../../../shared/step-navigation/steps-store-provider';
import { graphql } from '../../../utils/graphql';
import { useDsyncStore } from '../dsync-store-provider';

interface DsyncStepNavigationProps {
  stepsData: StepData[];
}

interface DsyncStepNavigationEnhancerProps {
  children: (props: StepNavigationProps) => ReactElement;
  stepsData: StepData[];
}

export const DsyncStepNavigationEnhancer: FC<
  Readonly<DsyncStepNavigationEnhancerProps>
> = ({ children, stepsData }) => {
  const { directory, setDsyncStore } = useDsyncStore();

  const stepsWithPath: StepDataWithPath[] = stepsData.map((step, index) => ({
    ...step,
    path: `/dsync/configure/${index + 1}`,
  }));

  const handleUpdateWithChanges = async (
    formData: StepsFormData<AnyObject>,
  ) => {
    const response = await graphql().UpdateDirectory({
      id: directory.id,
      ...formData,
    });

    if (response.data.portal_updateDirectory) {
      setDsyncStore({ directory: response.data.portal_updateDirectory });
    }
  };

  return children({
    onClickSwitchProvider: () => null,
    onUpdateWithChanges: handleUpdateWithChanges,
    provider: directory.type,
    stepsData: stepsWithPath,
    stepsUrlBase: '/dsync/configure',
  });
};

export const DsyncStepNavigation: FC<Readonly<DsyncStepNavigationProps>> = ({
  children,
  stepsData,
}) => (
  <DsyncStepNavigationEnhancer stepsData={stepsData}>
    {(stepNavigationProps) => (
      <StepNavigation {...stepNavigationProps}>{children}</StepNavigation>
    )}
  </DsyncStepNavigationEnhancer>
);
