import { InlineCode, Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ConnectionStepProps } from '../../../interfaces/step-props';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { Confirm } from '../../confirm';
import { CopyInput } from '../../input';
import { Link } from '../../link';
import { MetadataXmlStep } from './saml/metadata-xml-step';

export const ShibbolethGenericSamlCreateApplication: FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      NOTE: These instructions are for connecting to Shibboleth using the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/IDP4/pages/1265631694/SAML2SSOConfiguration"
      >
        generic SAML 2.0 configuration
      </Link>{' '}
      . If your Enterprise customer requires the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/IDP4/pages/1265631696/UnsolicitedSSOConfiguration"
      >
        UnsolicitedSSOConfiguration
      </Link>
      instead, please use the Shibboleth Unsolicited Provider.
    </Text>

    <Text>
      Set up generic SAML 2.0 in your Shibboleth instance. You'll need the
      following Assertion Consumer Service (ACS) URL. The ACS URL is the
      location your Shibboleth instance will redirect its authentication
      response to.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within your Shibboleth instance. Provide the value below to your
      Shibboleth generic SAML 2.0 settings.
    </Text>

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      For some setups, you can use the metadata found at the following SP
      Metadata link to configure the SAML connection.
    </Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Confirm
      label="I've created and configured generic SAML 2.0."
      onClick={handleNextStep}
    />
  </>
);

export const ShibbolethGenericSamlConfigureClaims: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>
      At minumum, the Attribute Statement in the SAML Response should include{' '}
      <InlineCode>id</InlineCode>, <InlineCode>email</InlineCode>,{' '}
      <InlineCode>firstName</InlineCode>, and <InlineCode>lastName</InlineCode>{' '}
      attributes. Refer to the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/CONCEPT/pages/928645122/SAMLAttributeNaming"
      >
        Shibboleth documentation
      </Link>{' '}
      for more information on adding and mapping attributes.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML attributes."
      onClick={handleNextStep}
    />
  </>
);

export const ShibbolethGenericSamlUploadMetadata: FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => (
  <MetadataXmlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mysamlserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysamlserver.com/..."
  >
    <Text>
      Download the IdP metadata from the Shibboleth instance. Refer to the{' '}
      <Link
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/CONCEPT/pages/928645275/MetadataForIdP"
      >
        Shibboleth documentation
      </Link>{' '}
      for more information on this metadata file. Upload the file below.
    </Text>
  </MetadataXmlStep>
);
