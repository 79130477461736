import { Text } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: VFC = () => (
  <StepContent confirmLabel="I’ve completed my assignments.">
    <Text>
      On the "Assignments" tab of your Okta Application click the "Assign"
      button and select "Assign to People".
    </Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/fd08f2c5-405e-45e6-829e-4a3c84d0ee31.png"
      width={1596}
    />

    <Text>
      Find the users that you wish to assign and click the "Assign" button next
      to them.
    </Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/fe484887-16bb-4b85-9065-0c8e2e922501.png"
      width={1596}
    />

    <Text>To complete assigning the users, click "Save and Go Back".</Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/e5d569a7-5a7f-4618-912a-79d1e71d4d6f.png"
      width={1596}
    />
  </StepContent>
);

export const assignPeopleGroupsToOktaApplication: StepData = {
  title: 'Assign People & Groups to Okta Application',
  component: Component,
};
